import { environment as original } from './environment.dev';

export const environment = {
  ...original,
  name: 'dev-without-excuseme-invoice',
  featureToggle: {
    ...original.featureToggle,
    withCallWaiter: false,
    withCallWaiterToRepeat: false,
    withCallWaiterToPay: false,
    withServiceCentre: false,
    withInvoice: false,
  }
};
